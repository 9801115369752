import React, {
  forwardRef, useCallback, useId, useMemo, useState
} from 'react';

import IconHidePassword from 'assets/icons/ic_hide_password.svg';
import IconViewPassword from 'assets/icons/ic_view_password.svg';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import mapModifiers from 'utils/functions';

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: 'text' | 'number' | 'email' | 'password' | 'tel';
  label?: string;
  subLabel?: string;
  error?: string;
  horizontal?: boolean;
  message?: string;
  loading?: boolean;
  isReadOnly?: boolean;
}

const InputRef: React.ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({
  type,
  label,
  subLabel,
  error,
  horizontal,
  message,
  loading,
  isReadOnly,
  ...props
}, ref) => {
  const id = useId();
  const [showPassword, setShowPassword] = useState(false);
  const handleClick = useCallback(() => setShowPassword(!showPassword), [showPassword]);

  const inputType = useMemo(() => {
    if (type === 'password') {
      if (showPassword) {
        return 'text';
      }
      return 'password';
    }
    return type;
  }, [type, showPassword]);

  return (
    <div
      className={mapModifiers(
        'a-input',
        type,
        error && 'error',
        horizontal && 'horizontal',
        isReadOnly && 'isReadOnly',
      )}
    >
      {label && (
        <div className="a-input_label">
          <label htmlFor={id}>
            <Text
              modifiers={['16x24', '600', 'gunmetal']}
            >
              {label}
            </Text>
          </label>
        </div>
      )}
      {subLabel && (
        <div className="a-input_subLabel">
          <label htmlFor={id}>
            <Text
              modifiers={['14x20', '500', 'gunmetal']}
            >
              {subLabel}
              {props.required && !isReadOnly && <span className="a-input_subLabel-required">*</span>}
            </Text>
          </label>
        </div>
      )}
      <div className="a-input_wrapper">
        <div className="a-input_wrapInput">
          <input
            {...props}
            className="a-input_input"
            type={inputType}
            ref={ref}
            placeholder={props.placeholder}
          />
          {type === 'password' && (
            <button type="button" className="a-input_showPassword" onClick={handleClick}>
              <img alt="icon_password" src={showPassword ? IconHidePassword : IconViewPassword} />
            </button>
          )}
          {
            loading && (
              <div className="a-input_showPassword">
                <Icon iconName="loading" />
              </div>
            )
          }
        </div>
        {error && (
          <div className={mapModifiers('a-input_messageError')}>
            <Text
              modifiers={['14x20', 'redOrange', '400']}
            >
              {error}
            </Text>
          </div>
        )}
        {
          message && !isReadOnly && (
            <div className={mapModifiers('a-input_messageError')}>
              <Text modifiers={['14x20', 'laSalleGreen', '400']}>
                {message}
              </Text>
            </div>
          )
        }
      </div>
    </div>
  );
};

const Input = forwardRef(InputRef);

Input.defaultProps = {
  children: undefined,
};

export default Input;
