import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useMemo,
} from 'react';
import {
  useForm,
  FormProvider,
  Controller,
} from 'react-hook-form';
import { useQuery } from 'react-query';

import { policiesData } from 'assets/dummyData/policies';
import Checkbox from 'components/atoms/Checkbox';
import Input from 'components/atoms/Input';
import Text from 'components/atoms/Text';
import MultiplePulldown, { OptionType } from 'components/molecules/MultiplePulldown';
import Collapse from 'components/organisms/Collapse';
import CustomModal from 'components/organisms/Modal';
import {
  getLangListService,
} from 'services/sourceParttimes';
import { PartTimesDataType } from 'services/sourceParttimes/types';
import { schemaJobUpdate } from 'utils/schemas';

type JobFormType = {
  language: string[];
  prjName: OptionType[] | null;
  otherName?: string;
  confirm: boolean;
};

export interface JobActionProps {
  handleForm: () => Promise<JobFormType | undefined>;
  isFormDirty: () => boolean;
  handleOthersForm: () => JobFormType | undefined;
  clearOthersForm: (fieldName?: keyof JobFormType) => void;
  handleReset: (data?: JobFormType) => void,
}

interface JobActionDataProps {
  projectList: OptionType[];
  isReadOnly?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Job = forwardRef<JobActionProps, JobActionDataProps>(({
  projectList,
  isReadOnly,
}, ref) => {
  const [open, setOpen] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const method = useForm<JobFormType>({
    mode: 'onChange',
    defaultValues: {
      language: [],
      prjName: null,
      otherName: '',
      confirm: false,
    },
    resolver: yupResolver(schemaJobUpdate),
  });

  const convertJobListData = (data?: PartTimesDataType[]) => {
    if (data && data?.length > 0) {
      return data.map((item) => ({
        id: item.id,
        value: item.code,
        label: item.name,
      }));
    }
    return [];
  };

  const { data: langRes } = useQuery(
    ['getLangList'],
    () => getLangListService(),
  );

  const languageList = useMemo(() => convertJobListData(langRes), [langRes]);

  const watchName = method.watch('prjName');

  const { isDirty } = method.formState;

  useImperativeHandle(ref, () => ({
    handleForm: async () => {
      method.trigger();
      let result: JobFormType | undefined;
      await method.handleSubmit((data: JobFormType) => {
        result = data;
      })();
      return result;
    },
    isFormDirty: () => isDirty,
    handleOthersForm: () => method.getValues(),
    clearOthersForm: (
      fieldName?: keyof JobFormType
    ) => {
      if (fieldName) {
        method.resetField(fieldName);
      } else {
        method.reset();
      }
    },
    handleReset: (data?: JobFormType) => method.reset(data),
  }));

  return (
    <Collapse
      hasBorder
      title="Công việc"
      active={open}
      handleOpen={() => setOpen(!open)}
    >
      <FormProvider {...method}>
        <form noValidate>

          <div className={`${isReadOnly ? 'd-none' : ''}`}>
            <Text modifiers={['14x20', '500', 'gunmetal']}>
              Ngoại ngữ
            </Text>
            <Controller
              name="language"
              render={({ field }) => (
                <div className="job_language u-mt-10">
                  {languageList.map((x) => (
                    <Checkbox
                      key={x.value}
                      variant="normal"
                      onChange={() => (field.value?.includes(x.value)
                        ? field.onChange(field.value?.filter((m: string) => m !== x.value))
                        : field.onChange([...field.value, x.value]))}
                      checked={field.value?.includes(x.value)}
                      label={x.label}
                    />
                  ))}
                </div>
              )}
            />
          </div>
          <div className="u-mt-8">
            <Controller
              name="prjName"
              render={({ field, fieldState }) => (
                <MultiplePulldown
                  label="Tên chương trình ứng tuyển"
                  error={fieldState?.error?.message}
                  placeholder="Nhập tên chương trình"
                  value={field.value}
                  options={projectList}
                  handleSelect={(option) => {
                    field.onChange(option);
                  }}
                  required
                />
              )}
            />
          </div>
          <div className={`u-mt-8 ${watchName?.find((item) => item.value === 'other') ? '' : 'd-none'}`}>
            <Controller
              name="otherName"
              rules={{ required: 'Nhập tên chương trình khác' }}
              render={({ field, fieldState }) => (
                <Input
                  {...field}
                  error={fieldState?.error?.message}
                  subLabel="Tên chương trình khác"
                  placeholder="Nhập tên chương trình khác"
                  required
                />
              )}
            />
          </div>
          <Controller
            name="confirm"
            render={({ field, fieldState }) => (
              <div className="u-mt-10">
                <Checkbox
                  variant="italic"
                  onChange={field.onChange}
                  checked={field.value}
                  link={{
                    title: 'Điều khoản và điều kiện',
                    onClick: () => setOpenModal(true),
                  }}
                  label="Tôi hiểu và đồng ý rằng hệ thống ATCMS sẽ lưu trữ và xử lý thông tin cá nhân của tôi theo quy định."
                />
                {fieldState?.error?.message && (
                  <div className="u-mt-8">
                    <Text
                      modifiers={['14x20', 'redOrange', '400']}
                    >
                      {fieldState?.error?.message}
                    </Text>
                  </div>
                )}
              </div>
            )}
          />
        </form>
      </FormProvider>
      <CustomModal
        showIconClose
        isOpen={openModal}
        handleClose={() => setOpenModal(false)}
        iconName="close"
      >
        <div className="job_modalContent u-mt-24">
          <div className="policies">
            <Text type="div" content={policiesData} />
          </div>
        </div>
      </CustomModal>
    </Collapse>
  );
});

export default Job;
