// import dayjs from 'dayjs';
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';

import Button from 'components/atoms/Button';
import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import NotifyModal from 'components/templates/NotifyModal';
import useWindowSize from 'hooks/useWindowSize';

interface CameraCaptureProps {
  name?: string;
  isSingle?: boolean;
  loading?: boolean;
  isError?: boolean;
  handleFinish?: (photos: File[]) => void;
  handleCheckFaceId?: (photos: File[]) => void;
  handleBack?: (photos?: File[]) => void;
  handleResetError?: () => void;
  notifySuccess?: {
    title: string;
    desc: string;
  }
  isConfirm?: boolean;
}

const CAPTURE_OPTIONS = {
  audio: false,
  video: {
    facingMode: 'user',
    width: { ideal: 1920 },
    height: { ideal: 1080 },
    frameRate: { max: 30 },
    aspectRatio: 16 / 9,
  },
};

export interface CameraCaptureRef {
  handleReCapture: () => void;
}

const CameraCapture = forwardRef<CameraCaptureRef, CameraCaptureProps>(
  ({
    name,
    isSingle,
    loading,
    isError,
    handleFinish,
    handleBack,
    handleResetError,
    notifySuccess,
    isConfirm,
    handleCheckFaceId,
  }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [error, setError] = useState(false);
    const [preview, setPreview] = useState('');
    const [photos, setPhotos] = useState<File[]>([]);
    const [errRequest, setErrRequest] = useState(false);
    const [isSupport, setIsSupport] = useState(false);

    const canvasRef = useRef<HTMLCanvasElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const { height: windowHeight } = useWindowSize();

    const stopSelfie = async () => {
      await navigator.mediaDevices.getUserMedia({ audio: false, video: true })
        .then((sm) => {
          if (!videoRef.current) return;
          videoRef.current.srcObject = sm;
          sm.getTracks().forEach((track) => {
            track.stop();
          });
        });
    };

    const handleCapture = useCallback(async () => {
      if (videoRef.current && canvasRef.current) {
        // const width = videoRef.current.videoWidth;
        // const height = videoRef.current.videoHeight;
        //* Workaround
        const isPortrait = window.innerWidth < window.innerHeight;
        const rotatedHeight = videoRef.current.videoHeight > videoRef.current.videoWidth
          ? videoRef.current.videoHeight
          : videoRef.current.videoWidth;
        const rotatedWidth = videoRef.current.videoWidth > videoRef.current.videoHeight
          ? videoRef.current.videoHeight
          : videoRef.current.videoWidth;
        const width = isPortrait
          ? rotatedWidth
          : videoRef.current.videoWidth;
        const height = isPortrait
          ? rotatedHeight
          : videoRef.current.videoHeight;

        const ctx = canvasRef.current.getContext('2d');
        const maxWidth = width > height ? 1280 : 720;
        const maxHeight = width > height ? 720 : 1280;

        let ratio = Math.min(maxWidth / width, maxHeight / height);
        if (ratio > 1) {
          ratio = 1;
        }

        const newWidth = width * ratio;
        const newHeight = height * ratio;
        canvasRef.current.width = newWidth;
        canvasRef.current.height = newHeight;
        if (ctx) {
          // if (isMobile) {
          ctx.translate(newWidth, 0);
          ctx.scale(-1, 1);
          // }
          ctx.drawImage(videoRef.current, 0, 0, newWidth, newHeight);
          //* Watermark
          // ctx.fillStyle = '#fff';
          // ctx.shadowColor = '#000';
          // ctx.shadowBlur = 4;
          // ctx.textBaseline = 'middle';
          // ctx.font = '32px sans-serif';
          // ctx.textAlign = 'right';

          // const x = newWidth - 16;
          // let y = 32;
          // const lineHeight = ctx.measureText('M').width * 1.2;
          // const lines = `${dayjs(new Date()).format('DD MMM YYYY [at] hh:mm:ss A')}`.split('\n');
          // lines.forEach((ele) => {
          //   ctx.fillText(ele, x, y);
          //   y += lineHeight;
          // });

          const imageDataURL = canvasRef.current.toDataURL('image/jpeg');
          setPreview(imageDataURL);
        }
        canvasRef.current.toBlob(async (b) => {
          if (b) {
            const file = new File([b], `${name}-${photos.length}.jpeg`, { type: 'image/jpeg' });
            setPhotos(isSingle ? [file] : [...photos, file]);
            if (handleCheckFaceId) {
              handleCheckFaceId([file]);
            }
          }
        }, 'image/jpeg');
      }
    }, [
      isSingle,
      name,
      photos,
      // isMobile,
      handleCheckFaceId,
    ]);

    const handleReset = () => {
      setPhotos([]);
      setIsOpen(false);
      setPreview('');
      setError(false);
      if (handleResetError) handleResetError();
    };

    const handleSubmit = async () => {
      if (handleFinish) handleFinish(photos);
    };

    const onBack = async () => {
      if (handleBack) {
        handleBack(photos);
      }
      await stopSelfie();
    };

    const closeReview = () => {
      if (isSingle) {
        setPhotos([]);
      }
      setPreview('');
    };

    useImperativeHandle(ref, () => ({
      handleReCapture: () => handleReset(),
    }));

    useEffect(() => {
      setError(!!isError);
      if (isError) {
        setIsOpen(false);
      }
    }, [isError]);

    useEffect(() => {
      if (!errRequest) {
        const streamPromise = navigator.mediaDevices.getUserMedia(
          CAPTURE_OPTIONS,
        ).catch((err: any) => {
          if (err.name === 'NotAllowedError') {
            setErrRequest(true);
          } else {
            setIsSupport(true);
          }
          return null;
        });
        if (videoRef) {
          streamPromise.then((stream) => {
            if (videoRef.current) {
              videoRef.current.srcObject = stream;
            }
          });
        }
        return () => {
          streamPromise.then((stream) => stream?.getTracks().forEach((track) => {
            track.stop();
          }));
        };
      }
      return () => { };
    }, [errRequest]);

    return (
      <>
        <div className="o-camera_back" onClick={onBack}>
          <Icon iconName={preview ? 'arrowPrevWhite' : 'arrowCirclePrevWhite'} size="28" />
        </div>
        <div
          className="o-camera"
          style={{ maxHeight: `${windowHeight}px` }}
        >
          <video
            id={`video-${name}`}
            ref={videoRef}
            className="o-camera_video"
            autoPlay
            playsInline
            controls={false}
            muted
          />
          <canvas
            id={`canvas-${name}`}
            ref={canvasRef}
          />
          <div className="o-camera_capture">
            <div className="o-camera_capture-button">
              <Button iconName="cameraWhite" sizeIcon="24" modifiers={['lg', 'primary']} onClick={handleCapture} />
            </div>
          </div>
          {preview && (
            <div className="o-camera_preview" style={{ backgroundImage: `url(${preview})` }}>
              <div className="o-camera_capturePreview">
                <div className="o-camera_capturePreview-button">
                  <Button disabled={loading} iconName="cameraGreen" sizeIcon="24" modifiers={['lg', 'outlineGreen']} onClick={closeReview}>
                    <Text
                      modifiers={['laSalleGreen', '16x24', '600', 'center']}
                    >
                      {isSingle ? 'Chụp lại' : 'Chụp tiếp'}
                    </Text>
                  </Button>
                </div>
                <div className="o-camera_capturePreview-button">
                  <Button
                    disabled={loading}
                    modifiers={['lg', 'primary']}
                    onClick={() => !loading && (isConfirm ? setIsOpen(true) : handleSubmit())}
                  >
                    <Text
                      modifiers={['white', '16x24', '400', 'center']}
                    >
                      Đi tiếp
                    </Text>
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
        <NotifyModal
          isOpen={isOpen}
          title={notifySuccess?.title}
          desc={notifySuccess?.desc}
          submitText="Đồng ý"
          backText="Chụp lại"
          handleBack={handleReset}
          handleSubmit={handleSubmit}
          loadingSubmit={loading}
        />
        <NotifyModal
          isError
          isOpen={error}
          title="Thất bại"
          desc="Đã có lỗi xảy ra. Vui lòng thử lại!"
          submitText="Chụp lại"
          handleSubmit={handleReset}
        />
        <NotifyModal
          isOpen={errRequest}
          desc="
            Chức năng này cần cho phép
            <br />
            truy cập camera
            <br />
            <br />
            Hướng dẫn bật truy cập camera
             <br />
            iOS: Vào cài đặt &gt; Safari &gt; Camera &gt; Chọn Cho phép
            <br />
            Android:
            <br />"
          submitText="Ok"
          handleSubmit={() => setErrRequest(false)}
        />
        <NotifyModal
          isOpen={isSupport}
          desc="
            Trình duyệt hoặc thiết bị của bạn
            <br />
            không hỗ trợ camera để thực hiện tính năng này.
            <br />
            Vui lòng kiểm tra lại."
          submitText="Ok"
          handleSubmit={() => {
            setIsSupport(false);
            onBack();
          }}
        />
      </>
    );
  }
);

export default CameraCapture;
