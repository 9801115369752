import React from 'react';
import { Outlet } from 'react-router-dom';

import Heading from 'components/atoms/Heading';
import Text from 'components/atoms/Text';
import CustomModal from 'components/organisms/Modal';
import useInAppDetect from 'hooks/useInAppDetect';

const MainLayout: React.FC = () => {
  const inApp = useInAppDetect();

  if (inApp) {
    return (
      <CustomModal isOpen modifiers={['width-336']}>
        <Heading type="h3" modifiers={['center', '32x42', '700', 'smokyBlack']}>Thông báo</Heading>
        <div className="t-mainLayout_inApp_desc">
          <Text modifiers={['14x20', '500', 'center', 'smokyBlack']}>
            Vui lòng copy link để mở bằng trình duyệt Safari hoặc Chrome.
          </Text>
        </div>
      </CustomModal>
    );
  }

  return (
    <div className="t-mainLayout">
      <Outlet />
    </div>
  );
};

MainLayout.defaultProps = {
  children: undefined,
};

export default MainLayout;
