/* eslint-disable no-param-reassign */
import React, { useEffect, useRef, useState } from 'react';

import Icon from 'components/atoms/Icon';
import Text from 'components/atoms/Text';
import useClickOutside from 'hooks/useClickOutside';
import useScrollInfinite from 'hooks/useScrollInfinite';
import mapModifiers from 'utils/functions';
import StringUtils from 'utils/removeDiacritics';

export interface OptionType {
  id?: string | number;
  label: string;
  value: string;
}

interface MultiplePulldownProps {
  id?: string;
  label?: string;
  noteLabel?: string;
  required?: boolean;
  placeholder?: string;
  value?: OptionType[];
  options: OptionType[];
  error?: string;
  disabled?: boolean;
  isSearch?: boolean;
  horizontal?: boolean;
  handleSelect?: (option: OptionType[]) => void;
  handleLoadMore?: () => void;
  loading?: boolean;
  number?: boolean;
}

const renderLabel = (
  info: Pick<MultiplePulldownProps, 'label' | 'id' | 'required' | 'noteLabel'>
) => {
  const {
    label, id, required, noteLabel
  } = info;

  if (!label) return null;

  return (
    <label className="m-pulldown_label" htmlFor={id}>
      {label}
      {noteLabel && <span className="m-pulldown_label-required">{noteLabel}</span>}
      {required && <span className="m-pulldown_label-required">*</span>}
    </label>
  );
};

const MultiplePulldown: React.FC<MultiplePulldownProps> = ({
  id,
  label,
  required,
  noteLabel,
  placeholder,
  value,
  options,
  error,
  disabled,
  isSearch,
  horizontal,
  handleSelect,
  handleLoadMore,
  loading,
  number,
}) => {
  const multiplePulldownRef = useRef<HTMLDivElement>(null);
  const [optionData, setOptionData] = useState<OptionType[]>([]);
  const [txtSearch, setTxtSearch] = useState('');
  const [selectedVal, setSelectedVal] = useState<OptionType[]>([]);

  const [isOpen, setIsOpen] = useState(false);
  const toggling = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };
  const { setNode } = useScrollInfinite(handleLoadMore);

  useClickOutside(multiplePulldownRef, () => {
    if (isOpen) {
      setIsOpen(false);
      if (txtSearch && handleSelect) {
        const find = options.find((item) => item?.label.includes(txtSearch));
        if (find) {
          handleSelect([...selectedVal, find]);
        }
      }
      // else {
      //   setTxtSearch(value?.label || '');
      // }
    }
  });

  useEffect(() => {
    if (txtSearch && isSearch) {
      const textSearch = StringUtils.removeDiacritics(txtSearch.toLocaleLowerCase());
      setOptionData(
        options.filter((item) => StringUtils.removeDiacritics(item?.label)
          .toLocaleLowerCase()
          .includes(textSearch))
      );
    } else {
      setOptionData(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [txtSearch, options]);

  useEffect(() => {
    if (value) {
      setSelectedVal(value);
    }
  }, [value]);

  return (
    <div
      className={`${mapModifiers(
        'm-multiplePulldown',
        error && 'error',
        horizontal && 'horizontal'
      )}`}
      ref={multiplePulldownRef}
    >
      {renderLabel({
        label,
        id,
        required,
        noteLabel,
      })}
      <div className="m-multiplePulldown_wrapHeader">
        <div
          className={mapModifiers('m-multiplePulldown_header', disabled && 'disabled')}
          onClick={toggling}
        >
          {isSearch ? (
            <input
              className="m-multiplePulldown_inputSearch"
              value={txtSearch}
              placeholder={placeholder}
              onChange={(event) => {
                const val = number
                  ? event.target.value.replace(/\D+/g, '').slice(0, 2)
                  : event.target.value;
                setTxtSearch(val);
              }}
            />
          ) : (
            <>
              <div
                className={`m-multiplePulldown_header_content${value ? '' : ' m-multiplePulldown_placeholder'
                  }`}
              >
                {(value && value?.length > 0) ? value.map((item, idx) => (
                  <div className="m-multiplePulldown_item-label" key={`option-val-${String(idx)}`}>
                    {item.label}
                    <div
                      className="m-multiplePulldown_item-close"
                      onClick={() => {
                        if (handleSelect) {
                          const index = selectedVal.findIndex((el) => el.id === item?.id);
                          if (index > -1) {
                            selectedVal.splice(index, 1);
                            handleSelect(selectedVal);
                          } else {
                            handleSelect([...selectedVal, item]);
                          }
                        }
                      }}
                    >
                      X
                    </div>
                  </div>
                )) : (
                  <span>{placeholder}</span>
                )}
              </div>
              <span
                className={
                  isOpen ? 'm-multiplePulldown_arrow opened' : 'm-multiplePulldown_arrow'
                }
              />
            </>
          )}
        </div>
        {error && (
          <div className="m-multiplePulldown_error">
            <Text modifiers={['14x20', 'redOrange', '400']}>{error}</Text>
          </div>
        )}
      </div>
      {isOpen && (
        <div className="m-multiplePulldown_wrapper">
          <ul className="m-multiplePulldown_list">
            {(() => {
              if (loading) {
                return (
                  <div className="loadingWrap">
                    <Icon iconName="loading" size="36" />
                  </div>
                );
              }
              return optionData.length ? (
                <>
                  {optionData.map((option, idx) => (
                    <React.Fragment
                      key={`multiplePulldown-${option.id}${idx.toString()}`}
                    >
                      <li
                        key={`multiplePulldown-${option.id}`}
                        className={mapModifiers(
                          'm-multiplePulldown_item',
                          value?.find((item) => item.id === option.id) && 'active'
                        )}
                        onClick={() => {
                          if (handleSelect) {
                            const index = selectedVal.findIndex((el) => el.id === option?.id);
                            if (index > -1) {
                              selectedVal.splice(index, 1);
                              handleSelect(selectedVal);
                            } else {
                              handleSelect([...selectedVal, option]);
                            }
                            setIsOpen(false);
                          }
                        }}
                      >
                        <span>{option.label}</span>
                      </li>
                    </React.Fragment>
                  ))}
                  {handleLoadMore && (
                    <div
                      className="t-tour_list_loadMore"
                      ref={(suggest) => setNode(suggest)}
                    />
                  )}
                </>
              ) : (
                <li className="m-multiplePulldown_item none">Không có dữ liệu</li>
              );
            })()}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MultiplePulldown;
