import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getDataSystemService from 'services/systems';
import { SystemDataTypes } from 'services/systems/types';

interface SystemState {
  dataSystem?: SystemDataTypes;
}

const initialState: SystemState = {
  dataSystem: undefined,
};

export const getSystemDataAction = createAsyncThunk<SystemDataTypes>(
  'systemReducer/getSystem',
  async (_, { rejectWithValue }) => {
    try {
      return await getDataSystemService();
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getSystemDataAction.fulfilled, ($state, action) => {
      $state.dataSystem = action.payload;
    });
  },
});

export default systemSlice.reducer;
