import axiosInstance from '../common/instance';

import { LocationItem } from './types';

export const getProvincesListService = async (): Promise<LocationItem[]> => {
  const res = await axiosInstance.get('locations/provinces/vietnamese');
  return res.data.data;
};

export const getDistrictListService = async (code?: string): Promise<LocationItem[]> => {
  const res = await axiosInstance.get(`locations/districts/${code}`);
  return res.data.data;
};

export const getWardsListService = async (code?: string): Promise<LocationItem[]> => {
  const res = await axiosInstance.get(`locations/wards/${code}`);
  return res.data.data;
};
